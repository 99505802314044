<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2019-11-19 09:58:18
 * @LastEditors: cyy
 * @LastEditTime: 2022-05-23 15:51:04
 -->
<template>
  <div class="course-table-contain">
    <div class="header-menu" v-if="$store.state.isWindowsAppV2">
      <span
        v-for="item in headerMenu"
        :key="item.id"
        class="item"
        :class="{ selected: item.id == menuId }"
        @click="menuId = item.id"
      >
        {{ item.name }}
      </span>
    </div>
    <template v-if="menuId == 1">
      <div class="left-wrap">
        <left class="course-table-left"></left>
      </div>
      <right class="course-table-right"></right>
    </template>
    <template v-else>
      <allCourse></allCourse>
    </template>
  </div>
</template>
<script>
import left from './classScheduleDate'
import right from './classScheduleTeacher'
import allCourse from './allCourse'
import bg from '@/mixin/background'

export default {
  name: 'ClassTable',

  mixins: [bg],

  components: {
    left,
    right,
    allCourse,
  },

  data() {
    return {
      menuId: 1,
      headerMenu: [
        { name: '直播日历', id: 1 },
        { name: '全部直播', id: 2 },
      ],
    }
  },

  methods: {
    /**
     * @desc 监听时间的选中 并赋值 上课列表会监听切换
     * @param value 当前选中的天的数据
     * @returns
     */
    eventSelected(value) {
      this.selected = value
    },
  },
}
</script>
<style lang="scss" scoped>
.left-wrap {
  height: 100%;
  border-right: 1px solid #ebebeb;
}
.course-table-contain {
  display: flex;
  background: #fff;
  margin: 20px;
  height: calc(100vh - 100px);
}
.course-table-left {
  height: 100%;
  width: 270px;
  position: relative;
}
.course-table-right {
  flex: 1;
  overflow-y: auto;
  @extend %scroll;
}
.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  background: #fff;
  padding: 0 5px;
  .item {
    font-size: 15px;
    color: #666666;
    line-height: 60px;
    padding: 0 15px;
    cursor: pointer;
    &.selected {
      color: #0aa29b;
      font-weight: bold;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        bottom: 0;
        background: #0aa29b;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
