<template>
  <div>
    <div class="title-content">
      <div class="top">
        <div class="return-curr-date">{{ showCurrTime.value }}</div>
        <div class="selected-time">
          <div class="vm prev" @click="prev"></div>
          <span
            :class="{
              vm: true,
              time: true,
              ['reset-class']:
                currentDay != newCurrentDay || monthFrequency != 0,
            }"
            @click="reset"
          >
            今天
          </span>
          <div class="vm next" @click="next"></div>
        </div>
      </div>
      <div class="course-table-time-list">
        <div class="cycle">
          <span v-for="(item, index) in cycle" :key="`cycle${index}`">
            {{ item }}
          </span>
        </div>
        <ul ref="carousel" class="course-table-container">
          <li
            v-for="(value, index) in [prevMonth, currMonth, nextMonth]"
            :key="index"
            class="vt item"
          >
            <span
              class="time"
              v-for="item in value"
              :key="item ? item.id : Math.random()"
            >
              <span
                @click="selectTime(item)"
                :class="{
                  disable: item && item.disable,
                  selected: item && selected.time === item.time,
                  haveClassDay:
                    item &&
                    haveClassDay[item.month] &&
                    haveClassDay[item.month].indexOf(item.time) > -1,
                  haveClassBlue: item && item.isEffective,
                  justToday:
                    item &&
                    currentDay == item.date &&
                    nowMonth == item.month &&
                    nowYear == item.year,
                }"
                class="text"
              >
                {{ item ? item.date : '' }}
              </span>
            </span>
          </li>
        </ul>
      </div>

      <div class="select-title">
        <div class="select-content">
          <ul class="ul-class" v-if="teacherList.length > 0">
            <li
              :class="{ liClass: true, selectActive: selectState == -1 }"
              @click="selectClick(-1, '')"
              :value="-1"
            >
              <el-image
                :src="require('@ass/img/icon_qbap.png')"
                class="img-class"
              ></el-image>
              <span class="span-name">当天全部安排</span>
            </li>

            <li
              v-for="(value, index) in teacherList"
              :key="index"
              :class="{ liClass: true, selectActive: selectState == index }"
              @click="selectClick(index, value)"
              :value="index"
            >
              <el-image :src="value.uphoto" class="img-class"></el-image>
              <span class="span-name">{{ value.uname }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Flickity from 'flickity'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('courseSchedule')

export default {
  name: 'table-component',

  data() {
    return {
      swiperLength: 3 - 1,
      prevMonth: [],
      currMonth: [],
      nextMonth: [],
      currIndex: 1,
      haveClassDay: {},
      showCurrTime: {},
      names: ['prevMonth', 'currMonth', 'nextMonth'],
      cycle: ['一', '二', '三', '四', '五', '六', '日'],
      currentDay: 0,
      newCurrentDay: 0,
      all: -1,
      selectState: -1,
      nowYear: '',
      nowMonth: '',
      monthFrequency: 0,
    }
  },
  props: {
    // teacherList: {
    //   type: Array,
    //   default() {
    //     return []
    //   }
    // }
  },
  updated() {},
  watch: {
    // teacherList(value) {
    //   console.log('value',value)
    // },
  },
  computed: {
    ...mapState(['teacher_id', 'teacherList', 'selected']),
  },
  mounted() {
    this.init()
  },

  methods: {
    ...mapMutations(['set_teacher_id', 'setSelected']),
    init() {
      const date = new Date()
      this.currentDay = parseInt(date.getDate())
      this.nowYear = parseInt(date.getFullYear())
      this.nowMonth = parseInt(date.getMonth() + 1)

      this.newCurrentDay = this.currentDay
      // console.log('date.getDay()', date.getDate(), this.selected)
      this.setSelected({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate(),
        day: date.getDay(),
        time: `${date.getFullYear()}-${this.numberFull10(
          date.getMonth() + 1
        )}-${this.numberFull10(date.getDate())}`,
      })

      const flkty = (this.flkty = new Flickity(this.$refs.carousel, {
        wrapAround: true,
        initialIndex: this.currIndex,
      }))
      flkty.on('change', e => {
        console.log('change', e)
        this.move(e)
      })
      this.getData(new Date())
      // 获取当月上课情况
      this.getHaveClassDayArrData(new Date())
    },

    // 选择时间
    selectTime(value) {
      this.newCurrentDay = parseInt(value.date)
      // console.log('selectTime', value)
      if (this.selected.time != value.time) {
        this.set_teacher_id('') //日期改变重置id
        this.selectState = -1 //日期改变重置左侧选中老师

        this.setSelected(value)

        //选择事件有变化
        this.haveClassDay = {}
        this.getHaveClassDayArrData(new Date(this.selected.time))
      }
    },

    // 前一页
    prev() {
      this.monthFrequency -= 1
      this.flkty.previous()
    },

    // 后一页
    next() {
      this.monthFrequency += 1
      this.flkty.next()
    },

    // 返回今天
    reset() {
      // console.log('reset()', this.currentDay, this.newCurrentDay)
      if (this.currentDay != this.newCurrentDay) {
        this.newCurrentDay = this.currentDay
        this.haveClassDay = {}
        this.currIndex = 1
        this.flkty.destroy()

        this.init()
      }

      if (this.monthFrequency != 0) {
        this.haveClassDay = {}
        this.currIndex = 1
        this.flkty.destroy()
        this.init()
        this.monthFrequency = 0
      }
    },

    move(current) {
      const isRightMoveOver =
        this.currIndex === this.swiperLength && current === 0
      const sign =
        (current > this.currIndex &&
          !(this.currIndex === 0 && current === this.swiperLength)) ||
        isRightMoveOver
          ? 1
          : -1
      this.currIndex = current
      this.jiSuanTime(sign)
    },

    // 计算用户滑动之后的时间
    jiSuanTime(sign) {
      let year = this.showCurrTime.year
      let month = this.showCurrTime.month + sign
      const monthEQ0 = month === 0

      if (monthEQ0 || month > 12) {
        year += sign
        month = monthEQ0 ? 12 : 1
      }

      const date = new Date(`${year}/${month}/1`)
      // 获取当月上课情况
      this.getHaveClassDayArrData(date)
      // 不受当月课程有没有课影响 直接计算天数
      this.getData(date, sign)
    },

    // 获取当月上课情况
    getHaveClassDayArrData(date) {
      this.$http({
        isMultipleSelf: 'replacePrev',
        name: 'getHaveClassDayArrData',
        url: '/TimeTable/scheduleTable',
        data: {
          dateTime: `${date.getFullYear()}-${date.getMonth() + 1}-1`,
          type: 1,
          teacher_id: this.teacher_id || '',
        },
        callback: ({ data: { haveClassDayArr = [] } }) => {
          this.haveClassDay = Object.assign({}, this.haveClassDay, {
            [date.getMonth() + 1]: haveClassDayArr,
          })
          // console.log('this.haveClassDay', this.haveClassDay)
        },
      })
    },

    // 获取下一页的index
    getNextIndex(sign) {
      return (this.currIndex + sign + this.names.length) % this.names.length
    },

    // 获取不同的数据
    getData(time, sign) {
      // console.log('time', time)
      if (sign) {
        const changeDataName = this.names[this.getNextIndex(sign)]
        this[changeDataName] = this.getListTimeDate(
          new Date(time.getFullYear(), time.getMonth() + sign, 1)
        )
      } else {
        this.prevMonth = this.getListTimeDate(
          new Date(time.getFullYear(), time.getMonth() - 1, 1)
        )
        this.currMonth = this.getListTimeDate(time)
        this.nextMonth = this.getListTimeDate(
          new Date(time.getFullYear(), time.getMonth() + 1, 1)
        )
      }

      this.showCurrTime = {
        value: `${time.getFullYear()}年${this.numberFull10(
          time.getMonth() + 1
        )}月`,
        year: time.getFullYear(),
        month: time.getMonth() + 1,
        yearDay: `${this.numberFull10(
          time.getMonth() + 1
        )}月${this.numberFull10(
          time.getDate()
        )}日 星期${this.setXingQingforChinese(time.getDay())}`,
      }
    },

    setXingQingforChinese(day) {
      if (day == 1) {
        return '一'
      } else if (day == 2) {
        return '二'
      } else if (day == 3) {
        return '三'
      } else if (day == 4) {
        return '四'
      } else if (day == 5) {
        return '五'
      } else if (day == 6) {
        return '六'
      } else {
        return '日'
      }
    },

    // 获取列表显示的时间数据
    getListTimeDate(date) {
      let dateList = []
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      // 当月数据
      const currMonthData = new Date(year, month, 0)
      // 当月最大天数
      const currMaxDay = currMonthData.getDate()
      // 剩余天数
      const daysRemaining = (currMaxDay - currMonthData.getDay()) % 7
      // 当前时间
      const newDate = new Date()

      for (let i = 1; i <= currMaxDay; i += 1) {
        const t = this.numberFull10(i)
        const itemDate = new Date(`${year}/${month}/${t}`)
        const time = `${year}-${this.numberFull10(month)}-${t}`
        dateList.push({
          time,
          year,
          month,
          date:
            time ===
            `${newDate.getFullYear()}-${this.numberFull10(
              newDate.getMonth() + 1
            )}-${newDate.getDate()}`
              ? '今'
              : t,
          id: Math.random(),
          day: itemDate.getDay(),
          // 是否超过了当前时间
          isEffective:
            itemDate.getTime() >=
            new Date(
              `${newDate.getFullYear()}/${newDate.getMonth() +
                1}/${newDate.getDate()}`
            ).getTime(),
        })
      }
      // 填充一些数据
      if (daysRemaining > 0) {
        dateList = new Array(7 - daysRemaining)
          .map(() => undefined)
          .concat(dateList)
      }
      return dateList
    },

    // 填充小于10的0前缀
    numberFull10(value) {
      return value < 10 ? `0${value}` : value
    },

    selectClick(index, value) {
      if (this.selectState != index) {
        this.selectState = index
        // console.log('value', index, value)
        this.set_teacher_id(value == '' ? '' : value.teacher_id)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.course-table-time-list {
  padding: 10px 0 0;
  background-color: #fff;
}
.cycle {
  font-size: 0;
  padding-bottom: 10px;
  span {
    width: 14.285%;
    font-weight: 400;
    font-size: 0.5rem;
    text-align: center;
    line-height: 0.7rem;
    vertical-align: top;
    display: inline-block;
    color: rgba(153, 153, 153, 1);
  }
}
.course-table-container {
  outline: 0;
  width: 270px;
  height: 235px;
  overflow: hidden;
  user-select: none;
  position: relative;
  white-space: nowrap;
  .item {
    font-size: 0;
    width: 100%;
    height: 100%;
    white-space: normal;
    .time {
      width: 14.285%;
      padding: 10px 0;
      vertical-align: top;
      display: inline-block;
      position: relative;
      .text {
        width: 20px;
        height: 20px;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        border-radius: 10px;
        line-height: 20px;
        position: relative;
        color: rgba(51, 51, 51, 1);
        &.disable {
          color: #a6a6a6;
        }
      }
      .selected {
        width: 20px;
        height: 20px;
        color: #fff !important;
        border-radius: 10px;
        background: #329891;
        &:after {
          display: none;
        }
      }
    }
    .haveClassDay {
      color: #333;
      background-color: rgba(50, 152, 145, 0.1);
      &.haveClassBlue {
        color: #329891;
      }
    }
  }
}
/**选中时间**/
.selected-time {
  font-size: 0;
  white-space: nowrap;

  .time {
    font-size: 14px;
    color: #a1a1a1;
    line-height: 14px;
    cursor: pointer;
  }
  .prev,
  .next {
    cursor: pointer;
    width: 50px;
    height: 30px;
    background: url('../../assets/img/course_table/jt.png') no-repeat center;
    background-size: 6px 10px;
    &:last-child {
      transform: rotate(180deg);
    }
  }
}
.return-curr-date {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  flex: 1;
  padding: 20px;
}
.flickity-slider {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}
.carousel-cell {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flickity-viewport {
  height: 100% !important;
}
.reset-class {
  color: #0aa29b !important;
}
.title-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  .top {
    display: flex;
    align-items: center;
  }
}
.select-title {
  flex: 1;
  overflow-y: auto;
  border-top: 1px solid #f5f5f5;
  margin: 10px 20px 0 20px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track-piece {
    height: 0;
    background-color: transparent;
  }
  .select-content {
    margin-top: 20px;
  }
}
.ul-class {
  vertical-align: middle;
  .liClass {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    cursor: pointer;

    .img-class {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      margin-right: 10px;
    }
    .span-name {
      width: 190px;
      color: #333333;
      font-size: 13px;
      line-height: 13px;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.selectActive {
  background: linear-gradient(90deg, #ffffff 0%, #f0f0f0 100%);
  border-radius: 4px;
}
.justToday {
  color: #0aa29b !important;
}

::v-deep button.flickity-button {
  display: none;
}
</style>
