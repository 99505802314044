<!--
 * @Descripttion: 空状态
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-19 09:58:18
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-06 18:06:20
 -->
<template>
  <div class="empty"  >
    <img src="@ass/img/icon_ksjt.png" />
    
    <p>{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: 'Empty',
  data(){
      return {
          text:'无课程安排，放松心情，休息一下吧~'
      }
  }
}
</script>
<style lang="scss" scoped>
.empty {
  img {
    width: 371px;
    height: 126px;
    margin: 0 auto;
  }
  
  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 18px;
    text-align: center;
    line-height: 22px;
    color: rgba(153, 153, 153, 1);
  }
  
}
</style>
