<template>
  <div>
    <div class="new-time">{{ currTime.yearDay }}</div>
    <li v-show="noCourse" style="padding: 50px 0;"><empty></empty></li>
    <div class="schedule-list">
      <div>
        <div class="live-open" v-if="liveList.length > 0">
          <span class="live-open-title">直播安排</span>
          <li
            v-for="(item, index) in liveList"
            :key="item.open_class_id + index"
            :class="{
              itemcla: true,
              shadow: `0_${item.open_class_id}` == activeTeacherCard,
            }"
            @mouseenter="mouseenter(`0_${item.open_class_id}`)"
            @mouseleave="mouseleave"
          >
            <div class="user-info">
              <img class="t1" :src="item.uphoto" alt="" />
              <span class="t2">{{ getdate(item.startTime) }}</span>
            </div>
            <span class="itemName" :title="item.name">{{ item.name }}</span>
            <span class="itemtype">
              公开课
              <span v-if="item.screen_type==2">竖屏直播</span>
              <span v-else>横屏直播</span>
            </span>
            <div
              :class="{
                link1: true,
                active: `0_${item.open_class_id}` == activeTeacherCard,
                activeright: `0_${item.open_class_id}` == activeTeacherCard,
              }"
            >
              <classesBegin class="vm table-classesBegin" :item="item" type="4">
                <!-- :item="item" -->
                <span>发起直播</span>
              </classesBegin>
            </div>
            <div
              :class="{
                link2: true,
                active: `0_${item.open_class_id}` == activeTeacherCard,
                activeright: `0_${item.open_class_id}` == activeTeacherCard,
              }"
              @click="liveOpenCourseClick(item)"
            >
              分享
            </div>
            <div
              :class="{
                link3: true,
                active: `0_${item.open_class_id}` == activeTeacherCard,
                activeleft: `0_${item.open_class_id}` == activeTeacherCard,
              }"
              @click="pushsetingclick(item)"
            >
              推送通知设置 
            </div>
        
          </li>
        </div>
      </div>

      <div>
        <div class="live-open" v-if="curseList.length > 0">
          <span class="live-open-title">课程安排</span>
          <li
            v-for="(item, index) in curseList"
            :key="item.correlation_id + index"
            :class="{
              itemcla: true,
              shadow: `1_${item.correlation_id}` == activeTeacherCard,
              courselive: item.course_type == 1,
              coursemin: item.course_type == 2,
            }"
            @mouseenter="mouseenter(`1_${item.correlation_id}`)"
            @mouseleave="mouseleave"
          >
            <div class="user-info">
              <img class="t1" :src="item.uphoto" alt="" />
              <span class="t2">{{ getdate(item.startTime) }}</span>
            </div>
            <span class="itemName" :title="item.name">
              {{ item.title + `《${item.name}》` }}
            </span>
            <span
              :class="{
                itemtype: true,
                itemlive: item.course_type == 1,
                itemmin: item.course_type == 2,
              }"
            >
              {{ item.open_class_id > 0 ? '公开课' : item.course_type == 1 ? '直播课' : '小班课' }}
            </span>
            <div
              :class="{
                link1: true,
                active: `1_${item.correlation_id}` == activeTeacherCard,
                activeright: `1_${item.correlation_id}` == activeTeacherCard,
              }"
            >
              <classesBegin class="vm table-classesBegin" :item="item" :type="item.open_class_id > 0 ? 4 : 3">
                <!-- :item="item" -->
                <span>发起直播</span>
              </classesBegin>
            </div>
            <div
              :class="{
                link2: true,
                active: `1_${item.correlation_id}` == activeTeacherCard,
              }"
              @click="item.open_class_id > 0 ? liveOpenCourseClick(item) : liveAndSmallClassClick(item)"
            >
              分享
            </div>
            <div
              :class="{
                link3: true,
                active: `1_${item.correlation_id}` == activeTeacherCard,
                activeleft: `1_${item.correlation_id}` == activeTeacherCard,
              }"
              v-if="item.course_type == 1"
              @click="item.open_class_id > 0 ? pushsetingclick(item) : notification1(item)"
            >
              推送上课通知
            </div>
          </li>
        </div>
      </div>
    </div>
    <!--分享课程直播-->
    <sharePop
      v-if="shareId"
      @closeFn="toggleShareId"
      :shareInfo="shareInfos"
    ></sharePop>

    <!-- 分享直播 -->
    <shareinfo
      v-if="liveOpenShare"
      :dialogstatus.sync="liveOpenShare"
      :shareinfoval="shareinfoval"
    ></shareinfo>

    <!-- 直播-公开课推送通知设置 -->
    <push-settings
      v-if="status2"
      :pushseting="pushseting"
      :dialogstatus.sync="status2"
    ></push-settings>

    <!-- 发送上课通知弹窗 -->
    <el-dialog
      title="发送通知（18人订阅）"
      :visible.sync="dialogVisible"
      width="422px"
      class="sure"
      :close-on-click-modal="false"
    >
      <span class="font1">
        一般无需手动发送上课通知，系统默认开课前10分钟自动发送；学生未收到时可以选择手动发送，避免重复发送，对学生产生打扰，建议慎重操作手动发送！
      </span>
      <span slot="title">发送通知（{{ count }}人订阅）</span>
      <span slot="footer" class="dialog-footer">
        <el-popover placement="right" width="305" trigger="hover">
          <div>
            <img src="../../assets/img/1.3.3/img_ylsktz.png" alt="" />
          </div>
          <el-button
            class="fl"
            style="padding-left: 0"
            slot="reference"
            type="text"
          >
            预览效果
          </el-button>
        </el-popover>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
/**
 * @description: 当天上课章节页面
 * @author: 彭明军
 * @update: 彭明军(2019-09-29 17：29)
 */
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('courseSchedule')
import empty from './showEmpty.vue'
// import NProgress from 'nprogress'
import API from '@/request/api'
import sharePop from '@/components/coursePop/sharePop'
import shareinfo from '@/components/openCourse/shareinfo'
import pushSettings from '../LiveBroadcastManagement/openCourse/components/pushSettings'

export default {
  name: 'GetCurrDayData',

  components: {
    empty,
    sharePop,
    shareinfo,
    pushSettings,
  },

  props: {},

  data() {
    return {
      scheduleInfo: {},
      gotoCourseDetailUrl: '',
      // isNodata: false,
      count: '',
      schedule_id: '',
      dialogVisible: false,
      // 分享数据
      shareId: '',
      shareInfos: {},
      curseList: [],
      liveList: [],
      shareinfoval: {},
      activeTeacherCard: '',
      liveOpenShare: false,
      status2: false,
    }
  },

  watch: {
    /**
     * @desc: 监听当天的选中 并去后台进行获取数据
     */
    selected() {
      // console.log('selected', this.selected)
      this.getCurrDaySchedule()
    },

    teacher_id() {
      // console.log('teacher_id', this.teacher_id)
      this.getCurrDaySchedule()
    },
    // teacherList(value) {
    //   console.log('value', value)
    //   this.getCurrDaySchedule()
    // }
  },

  filters: {
    getClassHour(v, item) {
      let newClassHour = []
      const leftTime = item.endTime - item.startTime

      const leftHour = Math.floor(leftTime / 3600)
      const minutes = Math.floor((leftTime - leftHour * 3600) / 60)

      newClassHour[0] = leftHour > 0 ? `${leftHour}小时` : ''
      newClassHour[1] = minutes > 0 ? `${minutes}分钟` : ''
      return newClassHour.join('')
    },
  },

  created() {
    this.getCurrDaySchedule()
  },
  computed: {
    ...mapState([
      'isWindowsApp',
      'userInfo',
      'teacher_id',
      'teacherList',
      'selected',
    ]),
    currTime() {
      return {
        yearDay: `${this.selected.month}月${
          this.selected.date
        }日 星期${this.setXingQingforChinese(this.selected.day)}`,
      }
    },
    noCourse() {
      return this.curseList.length == 0 && this.liveList.length == 0
    },
  },

  methods: {
    ...mapMutations(['setTeacherList']),
    /**
     * @desc: 获取当天的数据
     * @param: dateTime 哪一天
     */
    getCurrDaySchedule() {
      // NProgress.start()
      // this.$emit('getLoadingStatus', true)

      this.$root.$http({
        name: 'getCurrDayScheduleData',
        url: '/TimeTable/getDayData',
        isMultipleSelf: 'replacePrev',
        errorMessage: '获取当天课程失败，请检查网络或重试',
        data: Object.assign(
          { type: 1 },
          {
            dateTime: this.selected.time,
            teacher_id: this.teacher_id,
          }
        ),
        callback: data => {
          this.curseList = data.data.curseList
          this.liveList = data.data.liveList

          if (!this.teacher_id) {
            this.setTeacherList(data.data.teacherList)
          }
        },
        error: () => {},
        complete() {},
      })
    },

    // 分享直播
    liveOpenCourseClick(item) {
      // console.log('liveOpenCourseClick',item)
      this.shareinfoval = item
      this.liveOpenShare = true
    },

    //直播和小班分享
    liveAndSmallClassClick(item) {
      const self = this
      self.$http({
        name: API.coursePosterShare_Api.name,
        url: API.coursePosterShare_Api.url,
        data: {
          course_id: item.course_id,
        },
        callback({ code, data }) {
          if (code == 200) {
            self.shareId = item.course_id
            self.shareInfos = data
          }
        },
      })
    },
    sharePopPage() {},

    // 显示分享弹窗页面
    toggleShareId() {
      this.shareId = ''
    },
    setXingQingforChinese(day) {
      if (day == 1) {
        return '一'
      } else if (day == 2) {
        return '二'
      } else if (day == 3) {
        return '三'
      } else if (day == 4) {
        return '四'
      } else if (day == 5) {
        return '五'
      } else if (day == 6) {
        return '六'
      } else {
        return '日'
      }
    },
    getdate(t) {
      let date = new Date()
      date.setTime(t * 1000)
      let time =
        date.getHours() + ':' + this.numberFull10(parseInt(date.getMinutes()))
      return time
    },
    mouseenter(v) {
      // console.log('mouseenter')
      this.activeTeacherCard = v
    },
    mouseleave() {
      this.activeTeacherCard = ''
    },
    //公开课推送通知设置
    pushsetingclick(item) {
      this.pushseting = item
      this.status2 = true
    },
    sure() {
      this.$http({
        url: `/user/sendNotice`,
        data: {
          schedule_id: this.schedule_id,
        },
        callback: () => {
          this.dialogVisible = false
          this.$root.prompt({
            msg: '发送成功！',
            type: 'success',
          })
        },
      })
    },
    //推送上课通知
    notification1(item) {
      this.schedule_id = item.correlation_id
      this.$http({
        url: `/user/sendNotice?schedule_id=${item.correlation_id}`,
        callback: ({ count }) => {
          this.count = count
          this.dialogVisible = true
        },
      })
    },
    // 填充小于10的0前缀
    numberFull10(value) {
      return value < 10 ? `0${value}` : value
    },
  },
}
</script>
<style lang="scss" scoped>
/**列表**/
.schedule-list {
  //   margin-top: 54px;
  // height: calc(100% - 62px);
  // overflow-y: scroll;

  // 将滚动条样式去掉
  // &::-webkit-scrollbar {
  //   width: 0;
  //   height: 0;
  // }
  // &::-webkit-scrollbar,
  // &::-webkit-scrollbar-thumb,
  // &::-webkit-scrollbar-track-piece {
  //   height: 0;
  //   background-color: transparent;
  // }
}
.new-time {
  margin: 20px 20px 20px 20px;
  font-weight: bold;
  color: #333333;
  font-size: 17px;
  line-height: 22px;
}
.live-open {
  margin: 20px;
  padding-right: 20px;
  .live-open-title {
    height: 17px;
    font-size: 17px;
    font-weight: bold;
    color: #333333;
  }
  .itemcla {
    margin-top: 20px;
    // height: 100px;
    background: #f0f5fe;
    padding: 13px 20px 20px 20px;
    cursor: pointer;
    width: calc(100% - 20px);
    border-radius: 4px;
  }

  .user-info {
    display: flex;
    margin-bottom: 13px;
    align-items: center;
    .t1 {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .t2 {
      flex: 1;
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }
  }
  .itemName {
    font-size: 14px;
    color: #333333;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
  .itemtype {
    margin: 10px 0 10px 0;
    display: block;
    height: 12px;
    font-size: 12px;
    color: #4688fa;
  }
  .link1 {
    // text-align: center;
    display: inline-block;
    width: 60px;
    font-size: 12px;
    border-right: 1px solid #d2d2d2;
    color: #d2d2d2;
    line-height: 12px;
  }
  .link2 {
    width: 53px;

    font-size: 12px;
    display: inline-block;
    color: #d2d2d2;

    text-align: center;
    line-height: 12px;
  }
  .link3 {
    text-align: center;
    width: 90px;
    line-height: 12px;

    font-size: 12px;
    display: inline-block;
    color: #d2d2d2;

    border-left: 1px solid #d2d2d2;
  }
}

.active {
  color: #0aa29b !important;
  transition: all 0.2s ease;
}
.activeright {
  border-right-color: #0aa29b !important;
}
.activeleft {
  border-left-color: #0aa29b !important;
}
.shadow {
  // box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 4px 8px 0px #e9dfdf;
  transition: all 0.2s ease;
}
.courselive {
  background: #fbecec !important;
}
.coursemin {
  background: #fdf3e4 !important;
}
.itemlive {
  color: #e96263 !important;
}
.itemmin {
  color: #d39233 !important;
}
.font1 {
  line-height: 22px;
}
</style>
